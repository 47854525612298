import { useCommonErrorMessage } from "~/composables/useCommonErrorMessage";

export default defineNuxtRouteMiddleware((to) => {
  const { $pinia } = useNuxtApp();
  const { user } = storeToRefs(useUserStore($pinia));
  const { tokenUser } = storeToRefs(useTokenUserStore($pinia));

  if (user.value && user.value.premium_type && to.path !== "/account") {
    return navigateTo("/account");
  }

  if (
    tokenUser.value &&
    tokenUser.value.is_pro &&
    ["will_renew", "grace_period", "unknown"].includes(tokenUser.value.subscription_status)
  ) {
    throw createError({
      message: useCommonErrorMessage().alreadyProMessage.value,
    });
  }
});
